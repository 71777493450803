import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { AfterPageProps } from '../../../@sprinx/react-after-razzle';

export type LoginPageadvantageSectionProps = AfterPageProps;
export type LoginPageadvantageSectionClassNameKey = 'advantageSection' | 'advantageHeading' | 'advantages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<LoginPageadvantageSectionClassNameKey, {}>({
    advantageSection: {
      paddingBottom: theme.spacing(6),
      letterSpacing: '1px',
    },
    advantages: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'space-evenly',
      margin: 'auto',
      color: '#404145',
      fontSize: theme.spacing(2),
      flexWrap: 'wrap',
      '@media (min-width: 731px)': {
        maxWidth: '1100px',
      },
      '@media (max-width: 730px)': {
        maxWidth: '440px',
      },
      '@media(max-width:430px)': {
        width: 'min-content',
      },
    },
    advantageHeading: {
      textAlign: 'center',
      color: '#0F3457',
      fontSize: theme.spacing(4),
      fontWeight: 'bold',
    },
  }),
);

const LoginPageadvantageSection: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <div className={classes.advantageSection}>
      <div className={classes.advantageHeading}>Efektivní řešení firemních nákupů</div>
      <div className={classes.advantages}>
        <div>
          <img src='loginPagePictures/savings.png' alt='Individuální ceny' />
          <div>Individuální ceny</div>
        </div>
        <div>
          <img src='loginPagePictures/clothingBranding.png' alt='Branding oděvů' />
          <div>Branding oděvů</div>
        </div>
        <div>
          <img src='loginPagePictures/discountedTransport.png' alt='Zvýhodněná doprava' />
          <div>Zvýhodněná doprava</div>
        </div>
        <div>
          <img src='loginPagePictures/customerSupport.png' alt='Zákaznická podpora' />
          <div>Zákaznická podpora</div>
        </div>
      </div>
    </div>
  );
};
LoginPageadvantageSection.displayName = 'LoginPageadvantageSection';

export default LoginPageadvantageSection;
